import _, { isEmpty } from 'underscore';
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useAsync } from 'react-async';
import { v4 as uuidv4 } from 'uuid';

import { ChatList } from './chat-list';
import { MessageInput } from "./message-input";
import { ConversationHistory } from './conversation-history';
import { Navbar } from './navbar';
import {
  getTitleFromMessages,
  defaultConversation,
} from './utils';
import { useLocalStorage } from './hooks/use-local-storage';
import { LocalStorageKeys, ChatGPTRoles } from './constant';

const newMessage = (data) => ({
  id: uuidv4(),
  timeStamp: new Date(),
  ...data,
});

const sendMessage = async ([{ message, conversationId, role, temperature } = {}] = []) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ message, conversationId, role, temperature }),
  };

  let response;

  if (role === ChatGPTRoles.Default) {
    response = await fetch(`/api/chat`, requestOptions);
  } else {
    response = await fetch(`/api/message`, requestOptions);
  }
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export const ChatMain = () => {
  const inputRef = useRef(null);
  const [conversationHistory, setConversationHistory] = useLocalStorage(LocalStorageKeys.ConversationHistory, []);
  const [currentConversation, setCurrentConversation] = useLocalStorage(LocalStorageKeys.Conversation, defaultConversation());
  const [chatOptions, setChatOptions] = useLocalStorage(LocalStorageKeys.ChatOptions, { role: ChatGPTRoles.Default });
  const [currentMessage, setCurrentMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const messages = useMemo(() => _.get(currentConversation, 'messages', []), [currentConversation]);
  const { data, setData, isLoading, run } = useAsync({
    deferFn: sendMessage,
  });

  const toggleDrawer = () => {
    setIsChecked(!isChecked);
  };

  const onClickSend = () => {
    run({ message: currentMessage, conversationId: currentConversation.id, ...chatOptions });
    const updatedConversation = {
      ...currentConversation,
      messages: [...messages, newMessage({ text: currentMessage, role: 'user' })]
    };
    setCurrentConversation(updatedConversation);
    setCurrentMessage('');
    if (_.findWhere(conversationHistory, { id: currentConversation.id })) {
      const updatedConversationHistory = conversationHistory.map(con => con.id === currentConversation.id ? updatedConversation : con);
      setConversationHistory(updatedConversationHistory);
    }
  };

  const updateConversationHistory = (conversation, conversationHistory) => {
    // current conversation is empty, do nothing
    if (_.isEmpty(conversation.messages)) {
      return;
    }
    // current conversation has data, so we might need to update conversation history
    const storedConversation = _.find(conversationHistory, con => con.id === conversation.id);
    let shouldUpdate = false;
    let updatedConversationHistory;
    // current conversation is not present in conversation history, so we need to add it
    if (!storedConversation) {
      updatedConversationHistory = [
        ...conversationHistory,
        { ...conversation, title: getTitleFromMessages(conversation.messages), created: new Date(), modified: new Date()}
      ];
      shouldUpdate = true;
    } else if (storedConversation.messages.length !== conversation.messages.length) {
      // current conversation is present in conversation history, but the messages are different, updating it
      updatedConversationHistory = conversationHistory.map(con => (con.id === conversation.id ? { ...con, messages: conversation.messages, modified: new Date() } : con));
      shouldUpdate = true;
    }

    if (shouldUpdate) {
      setConversationHistory(updatedConversationHistory);
      setCurrentConversation(defaultConversation());
    }
  };

  const onClickNew = (options = {}) => {
    if (isChecked) {
      toggleDrawer();
    }
    setChatOptions(prev => ({ ...prev, ...options }));
    if (!isEmpty(messages)) {
      setCurrentConversation(defaultConversation());
      updateConversationHistory(currentConversation, conversationHistory);
    }
    inputRef.current.focus();
  };

  const onSwitchConversation = (conversation) => {
    updateConversationHistory(currentConversation, conversationHistory);
    setCurrentConversation(conversation);
    toggleDrawer();
    inputRef.current.focus();
  };

  const onDeleteConversation = (conversation) => {
    const updatedConversationHistory = conversationHistory.filter(con => con.id !== conversation.id);
    setConversationHistory(updatedConversationHistory);
    setCurrentConversation(defaultConversation());
  };

  useEffect(() => {
    if (data) {
      const updatedConversation = {
        ...currentConversation,
        messages: [...messages, newMessage(data)]
      };
      setCurrentConversation(updatedConversation);
      setData(null);
      inputRef.current.focus();
    }
  }, [data, currentConversation, setData, messages, setCurrentConversation]);

  return (
    <div className="drawer drawer-mobile">
      <input id="my-drawer-1" type="checkbox" className="drawer-toggle" checked={isChecked} onChange={toggleDrawer}/>
      <div className="drawer-content flex flex-col items-center justify-center">
        <Navbar drawerId="my-drawer-1" onClickNew={onClickNew} chatOptions={chatOptions} />
        <main className="relative h-full w-full flex flex-col flex-1 overflow-hidden">
          <div className="flex-1 overflow-hidden">
            <ChatList messages={messages} isLoading={isLoading} />
          </div>
          <div className="absolute bottom-0 left-0 w-full">
            <MessageInput
              onClickSend={onClickSend}
              currentMessage={currentMessage}
              setCurrentMessage={setCurrentMessage}
              disabled={isLoading}
              inputRef={inputRef}
            />
            <div className="h-8"></div>
          </div>
        </main>
      </div> 
      <div className="drawer-side">
        <label htmlFor="my-drawer-1" className="drawer-overlay"></label> 
        <ConversationHistory
          onClickNew={onClickNew}
          conversationHistory={conversationHistory}
          onSwitchConversation={onSwitchConversation}
          onDeleteConversation={onDeleteConversation}
          currentConversationId={currentConversation.id}
          chatOptions={chatOptions}
        />
      </div>
    </div>  
  );
};
