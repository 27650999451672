import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import RemarkMath from "remark-math";
import RehypeKatex from "rehype-katex";
import 'katex/dist/katex.min.css';
import RemarkGfm from "remark-gfm";
import RehypePrsim from "rehype-prism-plus";

import { formatTime } from './utils';
import { Roles } from './constant';
import robotAvatar from './images/robot-1.png';
import userAvatar from './images/BB.png';

export const ChatMessage = React.memo(({ message }) => {
  return (
    <div className={`w-full bg-base-100 chat ${
      message.role === Roles.Assistant ? 'chat-start' : 'chat-end'
    }`}>
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          <img alt="avatar" src={message.role === Roles.Assistant ? robotAvatar : userAvatar} />
        </div>
      </div>
      <div className="chat-header">
        <span className="chat-name">{message.role === Roles.Assistant ? 'ChatGPT' : 'You'}</span>&nbsp;
        <time className="text-xs opacity-50">{formatTime(message.timeStamp)}</time>
      </div>
      <div className="chat-bubble">{message.role === Roles.User
        ? message.text
        : <ReactMarkdown
            remarkPlugins={[RemarkMath, RemarkGfm]}
            rehypePlugins={[RehypeKatex, RehypePrsim]}
          >
            {message.text}
          </ReactMarkdown>}
      </div>
    </div>
  );
});

ChatMessage.displayName = 'ChatMessage';
