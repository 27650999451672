import React from "react";
import { PaperPlane } from './icons';

export const MessageInput = ({
  onClickSend,
  currentMessage,
  setCurrentMessage,
  disabled,
  inputRef,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (currentMessage.trim() !== "") {
        onClickSend();
      }
    }
  };

  const sendButtonDisabled = currentMessage.trim() === "" || disabled;

  return (
    <div className="flex flex-row items-center justify-between w-full px-4 py-2 bg-base-100 relative">
      <textarea
        className="textarea textarea-accent resize-none text-base h-9 w-full focus:outline-none"
        ref={inputRef}
        id="message-input"
        autoFocus
        autoComplete="off"
        value={currentMessage}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        onChange={(event) => setCurrentMessage(event.target.value)}
        placeholder="Type your message here..."
      />
      <button
        aria-label="send message button"
        type="submit"
        disabled={sendButtonDisabled}
        onClick={onClickSend}
        className="absolute rounded-md text-gray-500 bottom-3 right-3 px-4 py-2 disabled:opacity-40"
      >
        <PaperPlane className="w-6 h-6 hover:bg-base-300 rounded"/>
      </button>
    </div>
  );
};
