import React from 'react';
import useTheme from './hooks/use-theme';
import { Sun, Moon } from './icons';
import { ChatGPTRoleDisplayNames } from "./constant";

export const Navbar = ({ drawerId, onClickNew, chatOptions}) => {
  const [theme, setTheme] = useTheme();
  const onToggleDarkMode = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };
  return (
    <div className="navbar bg-base-100">
      <div className="navbar-start">
        <label htmlFor={drawerId} className="btn btn-ghost btn-circle drawer-button md:hidden">
          <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
            <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z" />
          </svg>
        </label>
      </div>
      <div className="navbar-center">
        <a href="/" className="btn btn-ghost normal-case text-xl">{ChatGPTRoleDisplayNames[chatOptions.role]}</a>
      </div>
      <div className="navbar-end">
        <label className="swap swap-rotate">
          <input type="checkbox" onClick={onToggleDarkMode} />
          <Moon className="swap-on fill-current w-6 h-6"/>
          <Sun className='swap-off w-6 h-6 text-orange-300' />
        </label>
        <button className="btn btn-ghost btn-circle" onClick={() => onClickNew()}>
          <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
            <path d="M12 4a1 1 0 0 1 1 1v6h6a1 1 0 0 1 0 2h-6v6a1 1 0 0 1-2 0v-6H5a1 1 0 0 1 0-2h6V5a1 1 0 0 1 1-1z" />
          </svg>
        </button>
      </div>
    </div>
  );
};
