import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';

export const formatDate = (_date) => {
  let date = _date;
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatTime = (_date) => {
  let date = _date;
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const defaultConversation = () => ({
  id: uuidv4(),
  title: 'New Conversation',
  messages: [],
});

export const getTitleFromMessages = (messages) => {
  if (!_.isEmpty(messages)) {
    const firstMessage = messages[0];
    const firstFiveWords = firstMessage.text.split(' ').slice(0, 5).join(' ');
    return firstFiveWords;
  }
  return 'New Conversation';
};

export const parseMessageText = (messageText) => {
  const blocks = [];
  const regex = /```(\w+)\n([\s\S]*?)\n```|([^\n]+)/g;
  let match;
  while ((match = regex.exec(messageText))) {
    if (match[1]) {
      const lang = match[1];
      const code = match[2];
      blocks.push({ id: uuidv4(), type: "code", language: lang, text: code });
    } else {
      const text = match[3];
      if (text.trim() !== "```") {
        blocks.push({ id: uuidv4(), type: "text", text });
      }
    }
  }
  return blocks;
};
