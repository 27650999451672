import React from "react";
const DefaultClassName = "w-6 h-6";

export const PaperPlane = ({ className = DefaultClassName }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
    </svg>
  );
};

export const Sun = ({ className = DefaultClassName }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 1v2m0 18v2M4.22 4.22l1.42 1.42m12.72 12.72l1.42 1.42M1 12h2m18 0h2M4.22 19.78l1.42-1.42m12.72-12.72l1.42-1.42M12 5a7 7 0 0 1 7 7 7 7 0 0 1-7 7 7 7 0 0 1-7-7 7 7 0 0 1 7-7z" />
    </svg>
  );
};

export const Moon = ({ className = DefaultClassName }) => {
  return (
    <svg className={className} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
      <path d="M533.997 64.536C617.645 125.651 672 224.47 672 336c0 185.568-150.432 336-336 336-111.53 0-210.349-54.355-271.464-138.003C76.006 771.202 271.952 960 512 960c247.424 0 448-200.576 448-448 0-240.048-188.798-435.994-426.003-447.464zM288 224c17.6 0 32-14.4 32-32s-14.4-32-32-32h-64V96c0-17.6-14.4-32-32-32s-32 14.4-32 32v64H96c-17.6 0-32 14.4-32 32s14.4 32 32 32h64v64c0 17.6 14.4 32 32 32s32-14.4 32-32v-64h64z"></path>
      <path d="M416 416h-32v-32c0-17.6-14.4-32-32-32s-32 14.4-32 32v32h-32c-17.6 0-32 14.4-32 32s14.4 32 32 32h32v32c0 17.6 14.4 32 32 32s32-14.4 32-32v-32h32c17.6 0 32-14.4 32-32s-14.4-32-32-32z"></path>
    </svg>
  );
};

export const Function = ({ className = DefaultClassName }) => {
  return (
    <svg className={className} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200" stroke="currentColor">
      <path d="M459.29 485.65H274.82C158.58 485.65 64 391.08 64 274.83S158.58 64 274.82 64s210.82 94.58 210.82 210.82v184.47c0.01 14.56-11.79 26.36-26.35 26.36zM274.82 116.71c-87.19 0-158.12 70.93-158.12 158.12s70.93 158.12 158.12 158.12h158.12V274.82c0-87.18-70.93-158.11-158.12-158.11zM749.18 485.65H564.71c-14.55 0-26.35-11.8-26.35-26.35V274.82C538.35 158.58 632.93 64 749.18 64S960 158.58 960 274.82s-94.58 210.83-210.82 210.83z m-158.12-52.71h158.12c87.19 0 158.12-70.93 158.12-158.11 0-87.19-70.93-158.12-158.12-158.12s-158.12 70.93-158.12 158.12v158.11zM274.82 960C158.58 960 64 865.43 64 749.18s94.58-210.83 210.82-210.83h184.47c14.55 0 26.35 11.8 26.35 26.35v184.48C485.65 865.43 391.07 960 274.82 960z m0-368.94c-87.19 0-158.12 70.93-158.12 158.12S187.63 907.3 274.82 907.3s158.12-70.93 158.12-158.11V591.06H274.82zM749.18 960c-116.25 0-210.82-94.57-210.82-210.82V564.71c0-14.55 11.8-26.35 26.35-26.35h184.47c116.25 0 210.82 94.58 210.82 210.82S865.42 960 749.18 960zM591.06 591.06v158.12c0 87.18 70.93 158.11 158.12 158.11S907.3 836.36 907.3 749.18c0-87.19-70.93-158.12-158.12-158.12H591.06z" fill="currentColor"></path>
    </svg>
  );
};

export const ChatHistory = ({ className = DefaultClassName }) => {
  return (
    <svg className={className} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200" stroke="currentColor">
      <path d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667a424.832 424.832 0 0 1-200.874667-50.133334L85.333333 938.666667l50.176-225.706667A425.216 425.216 0 0 1 85.333333 512C85.333333 276.352 276.352 85.333333 512 85.333333z m0 85.333334a341.333333 341.333333 0 0 0-301.226667 502.016l14.933334 27.904-27.989334 125.696 125.781334-27.904 27.861333 14.890666A339.2 339.2 0 0 0 512 853.333333a341.333333 341.333333 0 0 0 0-682.666666z m42.666667 128v213.333333h170.666666v85.333333h-256V298.666667h85.333334z" fill="currentColor"></path>
    </svg>
  );
};