export const Roles = {
  User: 'user',
  Assistant: 'assistant',
};

export const LocalStorageKeys = {
  DarkTheme: 'dark-theme',
  ConversationHistory: 'conversationHistory',
  Conversation: 'conversation',
  Theme: 'theme',
  ChatOptions: 'chatOptions',
};

export const ChatGPTRoles = {
  Default: 'Default',
  TranslatorCN: 'TranslatorCN',
  TranslatorEN: 'TranslatorEN',
  TextPolisher: 'TextPolisher',
};

export const ChatGPTRoleDisplayNames = {
  [ChatGPTRoles.Default]: 'ChatGPT',
  [ChatGPTRoles.TranslatorCN]: 'Translator (CN)',
  [ChatGPTRoles.TranslatorEN]: 'Translator (EN)',
  [ChatGPTRoles.TextPolisher]: 'Text Polisher',
};