import React from 'react';
import _ from 'underscore';
import ScrollToBottom, { useScrollToBottom, useSticky } from 'react-scroll-to-bottom';

import robotAvatar from './images/robot-1.png';
import { ChatMessage } from './chat-message';

export const ChatListContent = React.memo(({ messages, isLoading }) => {
  const scrollToBottom = useScrollToBottom();
  const [sticky] = useSticky();

  return (
    <React.Fragment>
      <div className="flex flex-col items-start h-full bg-base-100">
        {messages.map((message) => <ChatMessage key={_.uniqueId(message.role)} message={message} />)}
        {isLoading && (
          <div className="flex flex-row items-start p-2 mb-2 shadow-md w-full bg-base-100">
            <div className="avatar">
              <div className="w-10 rounded-full">
                <img alt="avatar" src={robotAvatar} />
              </div>
            </div>
            <div className="flex flex-col w-full animate-pulse">
              <span className="text-xs text-base-500 w-1/4 h-4 bg-base-300 rounded-md mb-1"></span>
              <span className="text-xs text-base-500 w-1/2 h-4 bg-base-300 rounded-md mb-1"></span>
              <span className="text-xs text-base-500 w-1/2 h-4 bg-base-300 rounded-md mb-1"></span>
            </div>
          </div>
        )}
        <div className="w-full h-24 md:h-24 flex-shrink-0 bg-base-100"></div>
      </div>
      {!sticky && (
        <button
          className="cursor-pointer absolute right-6 bottom-[124px] md:bottom-[120px] z-10 rounded-full border border-gray-200 bg-base-50 text-gray-600 dark:border-white/10 dark:bg-white/10 dark:text-gray-200"
          onClick={scrollToBottom}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
          </svg>
        </button>
      )}
    </React.Fragment>
  );
});

ChatListContent.displayName = 'ChatListContent';

export const ChatList = (props) => (
  <ScrollToBottom className="h-full">
    <ChatListContent {...props} />
  </ScrollToBottom>
);
