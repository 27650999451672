import React from 'react';
import { ChatMain } from './components/chat-main';

function App() {
  return (
    <ChatMain />
  );
}

export default App;
