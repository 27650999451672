import React, { useMemo } from "react";

import { ChatGPTRoles, ChatGPTRoleDisplayNames } from "./constant";
import { Function, ChatHistory } from './icons';

export const ConversationHistory = ({
  onClickNew,
  onSwitchConversation,
  conversationHistory,
  onDeleteConversation,
  currentConversationId,
  chatOptions,
}) => {
  const sortedConversationHistory = useMemo(() => [...conversationHistory].sort((a, b) => new Date(b.created) - new Date(a.created)), [conversationHistory]); 
  return (
    <div className="menu p-4 w-80 bg-base-200 text-base-content">
      <h2 className="text-xl font-bold mb-4 text-base-500">Siyuan GPT</h2>
      <div tabIndex="0" className="collapse collapse-arrow">
        <input type="checkbox" />
        <div className="collapse-title w-full font-medium rounded-md flex items-center">
          <Function className='w-6 h-6 text-base-500' />
          <span className="pl-2">New Chat</span>
        </div>
        <ul className="collapse-content menu">
          {Object.keys(ChatGPTRoles).map((role) => (
            <li key={role} className="menu-item">
              <button
                className={`w-full py-2 px-4 rounded-md mb-2 text-left border-solid border-2 border-base-500 ${
                  chatOptions.role === role
                  ? 'active'
                  : ""
                }`}
                onClick={() => onClickNew({ role })}
              >
                {ChatGPTRoleDisplayNames[role]}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div tabIndex="1" className="collapse collapse-open">
        <input type="checkbox" />
        <div className="collapse-title w-full font-medium rounded-md flex items-center">
          <ChatHistory className='w-6 h-6 text-base-500' />
          <span className="pl-2">Chat History</span>
        </div>
        <ul className="collapse-content menu">
          {sortedConversationHistory.map((conversation) => (
          <li key={conversation.id} className="w-full group/item">
            <button
              className={`w-full py-2 px-4 rounded-md mb-2 text-left border-solid border-2 border-base-500 ${
                currentConversationId === conversation.id
                ? 'active'
                : ""
              }`}
              onClick={() => onSwitchConversation(conversation)}
            >
              <span className="truncate">{conversation.title}</span>
              <div className={`group/edit focus:bg-primary-focus invisible ml-auto rounded group-hover/item:visible ${
                currentConversationId === conversation.id
                ? 'hover:bg-primary-focus'
                : 'hover:bg-base-300'
              }`}>
                <svg onClick={(event) => {
                  event.stopPropagation();
                  onDeleteConversation(conversation);
                }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
              </div>
            </button>
          </li>
        ))}
        </ul>
      </div>      
    </div>
  );
};

export const SidePanel = ({ isOpen, togglePanel, children }) => {
  return (
    <div className={`z-40 fixed inset-y-0 left-0 w-64 bg-base-800 ${isOpen ? 'block transition-all duration-500 ease-in-out' : 'hidden transition-all duration-500 ease-in-out'}`}>
      {children}
      <button className="absolute top-2 left-[260px] text-white font-bold py-2 px-2 rounded-md border-2 border-white bg-transparent" onClick={togglePanel}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  );
};
